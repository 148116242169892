// Color
$c-bg             : white;
$c-bg-light       : #f2f2f2;
$c-text           : #333;
$c-text-light     : #a9a9a9;
$c-highlight      : #BDEAEE;

// Font
$f-family         : 'Roboto', Helvetica, Arial, clean, sans-serif;
$f-mono           : 'Roboto Mono',"Consolas","Lucida Console","DejaVu Sans Mono","Bitstream Vera Sans Mono",monospace;
$f-size           : 16px;
$f-lineheight     : 26px;
$f-weight-light   : 300;
$f-weight-normal  : 400;
$f-weight-bold    : 500;
$f-style          : normal;
$f-scale-s        : 0.889;
$f-scale-m        : 1.2;
$f-scale-l        : 1.4;
$f-scale-xl       : 1.6;
$f-scale-xxl      : 2.2;

// Content width
$w-grid-item      : 10rem;
$w-narrow         : 10rem;
$w-regular        : 22rem;
$w-wide           : 36rem;

// Padding
$sm-padding       : 1rem;
$lg-padding       : 1.5rem;
