@import "variables.sass";

.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw; }

// .............................................................................
// Photo Gallery
// .............................................................................
.photo-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: $w-wide;
    margin: auto;
    grid-gap: 0.5rem 0.5rem;
    align-items: center;
    justify-content: center; }

.photo-list-item {
    align-items: center;
    line-height: 0;
    padding: 0rem; }

.photo-list-img {
    width: 100%;
    height: 100%; }

#photo-gallery {
    padding: 0.25rem 0.5rem 3rem; }


// .............................................................................
// Photo Layout
// .............................................................................
// Phone case
.photo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 8.5rem);

    img {
        object-fit: contain;
        height: 100%;
        width: calc(100vw - 1.0rem); } }

// Laptop/iPad case
@media (min-width: 1025px) {
    .photo-wrapper {
        height: calc(100vh - 6rem); } }

// Horizontal phone case
@media (max-height: 600px) {
    .photo-wrapper {
        height: 50vh; }

    header {
        padding: 0 0 0.5rem; } }

.writing-photo {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: $w-wide;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1rem;
    grid-gap: 1rem 1rem;

    img {
        object-fit: contain;
        height: 100%;
        width: 100%; } }

.regular-photo {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: $w-regular;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1rem;

    img {
        object-fit: contain;
        width: 100%;
        height: 100%; } }
