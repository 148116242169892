@import "variables.sass";

 :root {
    font-size   : $f-lineheight;
    font-family : $f-family;
    font-weight : $f-weight-normal;
    font-style  : $f-style; }

@media (min-width : 500px) {
     :root {
      font-size: 1.8rem; } }
