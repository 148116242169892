@import "typography/type.sass";
@import "typography/settings.sass";
@import "reset.sass";
@import "variables.sass";
@import "photos.sass";

// General
html, body {
    height: 100%;
    margin: 0;
    background-color: $c-bg; }

body {
    // Keeps footer at bottom even if content is short
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    padding: 0.5rem;
    color: $c-text;
    margin-left: auto;
    margin-right: auto;
    max-width: $w-regular;
    @include aku-type(normal); }

p {
    padding-bottom: 1rem; }

h1 {
    @include aku-type(xxlarge);
    padding-top: 2rem;
    margin-bottom: 2rem;
    font-weight: $f-weight-bold; }

h2 {
    @include aku-type(medium);
    font-weight: $f-weight-bold;
    padding-bottom: 1rem;
    text-align: center; }

h3, h4, h5, h6 {
    font-weight: $f-weight-bold; }

hr {
    border: none;
    border-bottom: 1px dashed $c-text-light;
    background: none;
    margin-bottom: 1rem; }

em {
    font-style: italic; }

strong {
    font-weight: $f-weight-bold; }

// Table Styling
table, th, td {
    border: 1px solid $c-bg-light;
    border-collapse: collapse; }

th {
    font-weight: $f-weight-bold;
    background-color: $c-bg-light; }

th, td {
    text-align: left;
    padding: 5px; }

.post {
    padding: 0.5rem;
    color: $c-text;
    margin-left: auto;
    margin-right: auto;
    @include aku-type(normal);

    p, h1, h2, h3, h4, h5, h6, code, li, ul, ol, hr {
        margin-left: auto;
        margin-right: auto;
        max-width: $w-regular; }

    a {
        text-decoration: underline; }

    blockquote {
        padding-left: 1rem;
        font-style: italic; }

    ol, ul, dl {
        padding-bottom: 1rem;
        padding-left: 0.5rem;
        list-style-position: outside; }

    ul {
        list-style-type: circle; }

    ol {
        list-style-type: decimal; }

    li ul {
        padding-left: 1rem;
        padding-bottom: 0; }

    code {
        @include aku-type(small);
        font-family: $f-mono;
        background: $c-bg-light;
        padding: 0.1rem; }

    pre code {
        display: block;
        max-width: $w-regular;
        overflow-x: scroll;
        font-family: $f-mono;
        background: $c-bg-light;
        padding: 1rem;
        white-space: pre;
        line-height: 0.7rem; }

    pre {
        padding-bottom: 1rem; }

    img {
        display: flex;
        width: 100%;
        height: auto; }

    .footnotes {
        color: $c-text-light; } }

// ~ Header -------------------------------------------------------------------
header {
    padding: 0 0 0.5rem;

    h1 {
        @include aku-type(xlarge);
        padding: 0;
        margin: 0;
        letter-spacing: -0.5px;
        text-transform: uppercase;
        font-weight: $f-weight-normal; }

    ul {
        li {
            display: inline-block;
            padding-right: 0.5rem;
            color: $c-text-light;

            a:hover {
                color: $c-text; } } } }

@media (min-width: 600px) {
    header {
        padding: 1rem 0 0.5rem;
        display: grid;
        grid-template-columns: 6rem 1fr; }

    header ul {
        line-height: 1.5rem;
        text-align: right;

        li {
            padding-right: 0;
            padding-left: 1rem; } } }

// ~ Reading & Writing Archives -----------------------------------------------
.archive {
    padding-bottom: 2rem;

    .archive-date {
        font-family: $f-mono;
        color: $c-text-light;

        .archive-month {
            text-align: right;
            padding: 0; }

        .archive-year {
            margin-top: 1rem;
            border-bottom: 1px dashed $c-text-light;
            border-top: 1px dashed $c-text-light; } }

    .archive-day {
        font-family: $f-mono;
        padding-left: 1rem; }

    a {
        text-decoration: none;
        display: grid;
        grid-template-columns: 1fr min-content; } }

// ~ Reading & Writing Posts --------------------------------------------------
.post-title {
    text-align: center; }

.author {
    @include aku-type(large);
    color: $c-text-light;
    font-style: italic; }


// ~ Footer -------------------------------------------------------------------
svg {
    fill: $c-text-light;
    width: 0.75rem;
    height: 0.75rem; }

footer {
    height: 4rem;
    padding: 0.5rem 0;

    hr {
        margin-bottom: 0.5rem; }

    nav {
        display: grid;
        grid-template-columns: 1fr min-content;

        a svg {
            fill: $c-text; } }

    .photo-desc {
        padding-top: 1rem; }

    .footer-meta {
        padding-top: 1rem;
        @include aku-type(small);
        text-align: center;
        color: $c-text-light; } }

.photo-padding {
    padding-bottom: 5rem; }


